<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-xl-12 col-lg-12">
				<div class="card">
					<div class="card-header d-flex justify-content-between">
						<div class="header-title">
							<h4 class="card-title">Edit Student</h4>
						</div>
						<div class="float-right">
							<router-link :to="{ name: 'students' }" class="btn btn-primary mt-2 mr-1 btn-sm"><i class="fas fa-angle-double-left"></i> Back</router-link>
						</div>
					</div>
					<div class="card-body">
						<div class="new-user-info">
							<b-form @submit.prevent="editStudent">
								<div class="row">
									<div class="form-group col-md-4">
										<label>Name <span class="required">*</span></label>
										<b-form-input v-model="student.name" v-bind:class="{ 'is-invalid': Boolean(errors.name) }"></b-form-input>
										<div v-for="(val, index) in errors.name" :key="index" class="invalid-feedback">
											<p class="mb-0">{{ val }}</p>
										</div>
									</div>
									<div class="form-group col-md-4">
										<label>Enrollment Number </label>
										<b-form-input v-model="student.enrollment_number" v-bind:class="{ 'is-invalid': Boolean(errors.enrollment_number) }"></b-form-input>
										<div v-for="(val, index) in errors.enrollment_number" :key="index" class="invalid-feedback">
											<p class="mb-0">{{ val }}</p>
										</div>
									</div>
									<div class="form-group col-md-4">
										<label>Date of Birth <span class="required">*</span></label>
										<b-form-input type="date" v-model="student.dob" value-as-string v-bind:class="{ 'is-invalid': Boolean(errors.dob) }"></b-form-input>
										<div v-for="(val, index) in errors.dob" :key="index" class="invalid-feedback">
											<p class="mb-0">{{ val }}</p>
										</div>
									</div>									
									<div class="form-group col-md-4">
										<label>Phone Number</label>
										<b-form-input v-model="student.mobile" v-bind:class="{ 'is-invalid': Boolean(errors.mobile) }"></b-form-input>
										<div v-for="(val, index) in errors.mobile" :key="index" class="invalid-feedback">
											<p class="mb-0">{{ val }}</p>
										</div>
									</div>
									<div class="form-group col-md-4">
										<label>Email ID</label>
										<b-form-input v-model="student.email" v-bind:class="{ 'is-invalid': Boolean(errors.email) }"></b-form-input>
										<div v-for="(val, index) in errors.email" :key="index" class="invalid-feedback">
											<p class="mb-0">{{ val }}</p>
										</div>
									</div>
									<div class="form-group col-md-4">
										<label>Father's Name <span class="required">*</span></label>
										<b-form-input v-model="student.father_name" v-bind:class="{ 'is-invalid': Boolean(errors.father_name) }"></b-form-input>
										<div v-for="(val, index) in errors.father_name" :key="index" class="invalid-feedback">
											<p class="mb-0">{{ val }}</p>
										</div>
									</div>
									<div class="form-group col-md-4">
										<label>Mother's Name <span class="required">*</span></label>
										<b-form-input v-model="student.mother_name" v-bind:class="{ 'is-invalid': Boolean(errors.mother_name) }"></b-form-input>
										<div v-for="(val, index) in errors.mother_name" :key="index" class="invalid-feedback">
											<p class="mb-0">{{ val }}</p>
										</div>
									</div>
									<div class="form-group col-md-4">
										<label>Gender <span class="required">*</span></label>
										<select class="form-control" v-model="student.gender" v-bind:class="{ 'is-invalid': Boolean(errors.gender) }">
											<option v-if="!edit" value="">Select Gender</option>
											<option value="1">Male</option>
											<option value="2">Female</option>
											<option value="3">Others</option>
										</select>
										<div v-for="(val, index) in errors.gender" :key="index" class="invalid-feedback">
											<p class="mb-0">{{ val }}</p>
										</div>
									</div>
									<div class="form-group col-md-4">
										<label>State <span class="required">*</span></label>
										<b-form-select v-model="student.state_id" v-bind:class="{ 'is-invalid': Boolean(errors.state_id) }" @change="fetchDistricts()">
											<template #first>
												<b-form-select-option value="null" disabled>-- Select state --</b-form-select-option>
											</template>
											<b-form-select-option v-for="state in states" :key="state.id" :value="state.id">{{ state.state }}</b-form-select-option>
										</b-form-select>
										<div v-for="(val, index) in errors.state_id" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									    </div>
									</div>

									<div class="form-group col-md-4">
										<label>District <span class="required">*</span></label>
										<b-form-select v-model="student.district_id" v-bind:class="{ 'is-invalid': Boolean(errors.district_id) }">
											<template #first>
											<b-form-select-option value="null" disabled>-- Select district --</b-form-select-option>
											</template>
											<b-form-select-option v-for="district in districts" :key="district.id" :value="district.id">{{ district.district }}</b-form-select-option>
										</b-form-select>
										<div v-for="(val, index) in errors.district_id" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									    </div>
									</div>

									<div class="form-group col-md-4">
										<label>Department <span class="required">*</span></label>
										<b-form-select v-model="student.department_id" v-bind:class="{ 'is-invalid': Boolean(errors.department_id) }">
											<template #first>
											<b-form-select-option value="null" disabled>-- Select department --</b-form-select-option>
											</template>
											<b-form-select-option v-for="department in departments" :key="department.id" :value="department.id">{{ department.department }}</b-form-select-option>
										</b-form-select>
										<div v-for="(val, index) in errors.department_id" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									    </div>
									</div>

									<div class="form-group col-md-4">
									   <label>Course <span class="required">*</span></label>
										<b-form-select v-model="student.course_id" v-bind:class="{ 'is-invalid': Boolean(errors.course_id) }">
											<template #first>
											<b-form-select-option value="null" disabled>-- Select courses --</b-form-select-option>
											</template>
											<b-form-select-option v-for="course in courses" :key="course.id" :value="course.id">{{ course.course }}</b-form-select-option>
										</b-form-select>
										<div v-for="(val, index) in errors.course_id" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									    </div>
									</div>

									<div class="form-group col-md-4">
									   <label>Branch <span class="required">*</span></label>
										<b-form-select v-model="student.branch_id" v-bind:class="{ 'is-invalid': Boolean(errors.branch_id) }" @change="fetchSubBranches()">
											<template #first>
											<b-form-select-option value="null" disabled>-- Select branch --</b-form-select-option>
											</template>
											<b-form-select-option v-for="branch in branches" :key="branch.id" :value="branch.id">{{ branch.branch }}</b-form-select-option>
										</b-form-select>
										<div v-for="(val, index) in errors.branch_id" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									    </div>
									</div>

									<div class="form-group col-md-4">
										<label>Sub Branch</label>
										<b-form-select v-model="student.subbranch_id" v-bind:class="{ 'is-invalid': Boolean(errors.subbranch_id) }">
											<template #first>
											<b-form-select-option value="null" disabled>-- Select sub branch --</b-form-select-option>
											</template>
											<b-form-select-option v-for="sub_branch in sub_branches" :key="sub_branch.id" :value="sub_branch.id">{{ sub_branch.subbranch }}</b-form-select-option>
										</b-form-select>
										<div v-for="(val, index) in errors.subbranch_id" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									    </div>
									</div>

									<div class="form-group col-md-4">
										<label>Session <span class="required">*</span></label>
										<b-form-select v-model="student.session_id" v-bind:class="{ 'is-invalid': Boolean(errors.session_id) }">
											<template #first>
											<b-form-select-option value="null" disabled>-- Select session --</b-form-select-option>
											</template>
											<b-form-select-option v-for="session in sessions" :key="session.id" :value="session.id">{{ session.start }}-{{ session.end }}</b-form-select-option>
										</b-form-select>
										<div v-for="(val, index) in errors.session_id" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									    </div>
									</div>

									<div class="form-group col-md-4">
									   <label>Centre <span class="required">*</span></label>
									   <b-form-select v-model="student.centre_id" v-bind:class="{ 'is-invalid': Boolean(errors.centre_id) }">
											<template #first>
											<b-form-select-option :value="null" disabled>-- Select centre --</b-form-select-option>
											</template>
											<b-form-select-option v-for="centre in centres" :key="centre.id" :value="centre.id">{{ centre.centre }}</b-form-select-option>
										</b-form-select>
										<div v-for="(val, index) in errors.centre_id" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									    </div>
									</div>
                                    
                                     <div class="form-group col-md-4">
										<label class="control-label">Student Address</label>
										<b-form-textarea rows="3" v-model="student.address" v-bind:class="{ 'is-invalid': Boolean(errors.address) }"></b-form-textarea>
										<div v-for="(val, index) in errors.address" :key="index" class="invalid-feedback">
											<p class="mb-0">{{ val }}</p>
										</div>
								   </div>
								</div>
								
								<button type="submit" class="btn btn-primary">Submit</button>
							</b-form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: "EditStudent",

		data() {
			return {
				student: {
					name: '',
					dob:'',
					enrollment_number: '',
					state_id: null,
					district_id: null,
					centre_id: null,
					department_id: null,
					course_id: null,
					branch_id: null,
					subbranch_id: null,
					session_id: null,
					mobile: '',
					email: '',
					gender: '',
					father_name: '',
					mother_name: '',
					address: ''
				},				
				
				keyword: "",
				visible: false,
				status: '',
				token: "",
				errors: {},
				activate: false,

				states: {},
				districts: {},
				departments: {},
				branches: {},
				sub_branches: {},
				courses: {},
				centres: {},
                sessions: {},
                inputs: [
	                       {value: null}
	                    ],
			};
		},
        
        mounted() {
			
		},
   
		created() {	
		    this.fetchStudent();		
			this.fetchStates();
			this.fetchDepartments();
			this.fetchSessions();
			this.fetchCourses();
			this.fetchCentres();
			this.fetchBranches();
		},

	    methods: {

        	GetData()
        	{
               alert(this.student.dob); 
        	},

			add() {
	         this.inputs.push({ value: null });
	      },

	      remove(index) {
	         this.inputs.splice(index, 1);
	      }, 

		  decimalsOnly(event) {
		      let keyCode = event.keyCode;
		      if (keyCode >= 48 || keyCode <= 57 || keyCode == 46 ) {
		      	return;
		      } else {
		        event.preventDefault();
		      }
		    },
		    alphaSpacesOnly(event) {
		    	let keyCode = event.keyCode;
			    if ((keyCode >= 65 && keyCode <= 90) || 
			    	(keyCode >= 97 && keyCode <= 122) ||			    	
			    	(keyCode == 32)) {
			        return;
			    } else {
			    	event.preventDefault();
			    }
		    },
		   
			getToken() {
				if (localStorage.token) {
					this.token = localStorage.token;
				} else {
					this.$vToastify.error("Session expired, please login again");
					this.$router.push({ name: "auth.login" });
				}
			},
            
            fetchStudent() 
            {
			  let url = '/api/students/details?student_id=' + this.$route.params.id ;
            
	          this.getToken();
	          this.$axios
	            .get(url, {headers: {Authorization: 'Bearer ' + this.token,}})
	            .then(res => {
	               var responseData = res.data.data.student;               
	               this.student = responseData; 
	               this.fetchDistricts();
	               this.fetchSubBranches();	               
	               // this.keyword = this.student.name;	               
	               console.log(this.student);  
	            })
	            .catch(err => console.log(err));
			},


			fetchStates() {
				let url = "/api/students/states";			
				this.getToken();
				this.$axios
					.get(url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data;
						this.states = responseData.data;
					})
					.catch((err) => console.log(err));
			},
            
            fetchDistricts() {	
                // alert(this.student.state);			
				let url = "/api/students/districts?state_id=" + this.student.state_id;	
				this.getToken();
				this.$axios
					.get(url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data;
						this.districts = responseData.data;
					})
					.catch((err) => console.log(err));
			},

			fetchSessions() {
				let url = "/api/students/sessions";				
				this.getToken();
				this.$axios
					.get(url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data;
						this.sessions = responseData.data;
					})
					.catch((err) => console.log(err));
			},
            
            fetchBranches() {
				let url = "/api/students/branches";				
				this.getToken();
				this.$axios
					.get(url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data;
						this.branches = responseData.data;
					})
					.catch((err) => console.log(err));
			},

			fetchSubBranches() {				
				let url = "/api/students/sub_branches?branch_id=" + this.student.branch_id;	
				this.getToken();
				this.$axios
					.get(url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data;
						this.sub_branches = responseData.data;
					})
					.catch((err) => console.log(err));
			},

			fetchDepartments() {
				let url = "/api/students/departments";
				this.getToken();
				this.$axios
					.get(url, {
						headers: { Authorization: "Bearer " + this.token },
					})
					.then((res) => {
						var responseData = res.data;
						this.departments = responseData.data;
					})
					.catch((err) => console.log(err));
			},

			fetchCourses() {
				let url = "/api/students/courses";
				this.$axios
					.get(url, {
						headers: { Authorization: "Bearer " + this.token },
					})
					.then((res) => {
						var responseData = res.data.data;
						this.courses = responseData;
					})
					.catch((err) => console.log(err));
			},
            
            fetchCentres() {
				let url = "/api/students/centres";
				this.$axios
					.get(url, {
						headers: { Authorization: "Bearer " + this.token },
					})
					.then((res) => {
						var responseData = res.data.data;
						this.centres = responseData;
					})
					.catch((err) => console.log(err));
			},
            
			editStudent() {
				this.getToken();
				let formData = new FormData();
				formData.append("student_id", this.student.id);
				formData.append("name", this.student.name);
				formData.append("entrolment_no", this.student.enrollment_number);
				formData.append("state", this.student.state_id);
				formData.append("district", this.student.district_id);
				formData.append("centre", this.student.centre_id);
				formData.append("department", this.student.department_id);
				formData.append("course", this.student.course_id);
				formData.append("branch", this.student.branch_id);
				formData.append("sub_branch", this.student.subbranch_id);
				formData.append("session", this.student.session_id);
				formData.append("mobile", this.student.mobile);
				formData.append("email", this.student.email);
				formData.append("gender", this.student.gender);
				formData.append("father_name", this.student.father_name);
				formData.append("mother_name", this.student.mother_name);
				formData.append("address", this.student.address);
				formData.append("dob", this.student.dob);

				const config = {
					headers: {
						"content-type": "multipart/form-data",
						Authorization: "Bearer " + this.token,
					},
				};

				let url;
				url = "/api/students/update";

				this.$axios
					.post(url, formData, config)
					.then((response) => {
						if (response.data.status) {
							this.$vToastify.success(response.data.message);
						} else {
							this.$vToastify.error(response.data.message);
						}
						this.clearForm();
						this.$router.push({ name: "students" });
					})
					.catch((error) => {
						this.errors = {};
						if (error.response) {
							if (error.response.data.message) {
								console.log(error.response.data);
								this.errors = error.response.data.message;
								console.log(this.errors);
							} else {
								this.$vToastify.error("Error!");
							}
						} else {
							this.$vToastify.error("Error!");
						}
					});
			},

			clearForm() {	
			    this.fetchStudent();				
				this.fetchStates();
				this.fetchSessions();
				this.fetchCourses();
				this.fetchCentres();
				this.fetchBranches();
				this.fetchDepartments();
				this.inputs = [{value: null}];
				this.errors = {};
				
			},
		},

	};
</script>
